
















































































































































































































































































































































































































































































































import {Component, Vue} from 'vue-property-decorator';
import {Plan} from "@/typescript/types";
import WorkspacePage from "@/components/layout/WorkspacePage.vue";
import {CancelSubscription} from "@/graphql/WorkspaceSubscription";
import {WorkspaceQuery} from "@/graphql/workspace";
import Template from "@/components/templates/Template.vue";
import Downgrade from "@/components/subscription/Downgrade.vue";
import DowngradeWarningModal from './DowngradeWarningModal.vue';
import VerifyUser from '../auth/VerifyUser.vue';
import {feedback_types} from "@/graphql/feedback";
import {CreateFeedbackMutation} from '@/graphql/feedback';
import PlanFaq from "../PlanFaq.vue";




@Component({
  apollo: {
    feedback_types: {
      query: feedback_types,
      result(_result) {
        this.feedbackTypeId = _result.data.feedback_types[0].id;
      }
    },
  },
  components: {Template, WorkspacePage, PlanFaq}
})
export default class Plans extends Vue {
  plans: Plan = this.$store.state.plans;
  selectedPlanId: string | null = null;
  selectedPlanPrice: number | null = null;
  submitted: boolean = false;
  isActiveId: string | null = null;
  activePlanPrice: number | null = null;
  yearlyBillingPriceAsDefault: boolean = true;
  selectedPlanName: string | null = null;
  yearlyPicked: boolean = true;
  loadingPlanId: string | null = null;
  feedbackTypeId: string = ""

  get workspace() {
    return this.$store.state.workspace
  }

  get hasFreemiumAccess() {
    return this.me.has_freemium_access
  }

  get products() {
    if (this.$store.state.plans.length > 0) {
      let plansData = this.$store.state.plans;
      if (this.workspace.currentSubscription) {
        plansData = this.$store.state.plans.filter((plan: any) => {
          if (plan.price == 0) {
            return plan;
          } else if (plan.interval == this.workspace.currentSubscription.plan.interval) {
            return plan;
          }
        });
      }
      // Sort by price in ascending order
      plansData.sort((a: Plan, b: Plan) => a.price - b.price);
      return [...new Map(plansData.map((item: any) =>
        [item['stripeProduct'], item])).values()];
    }
    return [];
  }

  get planToBeSelected() {
    let plansToBeSelected = this.products.filter((product: any) => {
      return product.stripeProduct.stripe_product_id != this.workspace.currentSubscription.plan.stripeProduct.stripe_product_id;
    })

    return plansToBeSelected.sort((a: any, b: any) => b.price - a.price);
  }

  get me() {
    return this.$store.state.me;
  }

  get workspaceId() {
    return this.$store.state.workspace.id;
  }

  get onGuestAccount() {
    return this.$store.state.me.isFake;
  }

  get meId() {
    return this.$store.state.me.id;
  }

  get couponCode() {
    return this.$store.state.coupon_code;
  }

  get trialEnded() {
    return this.$store.state.trial_ended
  }

  get newUser() {
    let userCreationDate = new Date(this.me.created_at);
    let comparisonDate = new Date('2024-02-22');
    return userCreationDate > comparisonDate;
  }

  get currentSubscription() {
    return this.workspace.currentSubscription;
  }

  get activitesCreated() {
    return this.workspace.totalObjectivesCount
  }

  get userCreatedOneWeekAgo() {
    const userCreationDate = new Date(this.me.created_at); 
    const currentDate = new Date(); 
    const differenceInTime = currentDate.getTime() - userCreationDate.getTime(); 
    const differenceInDays = differenceInTime / (1000 * 3600 * 24); 
    return differenceInDays > 7; 
  }

  get filterDeletedWorkspaceMember() {
    if(this.workspace.members) {
      return this.workspace.members.filter((member: any) => {
        return member.user.deleted_at === null;
      })
    }
    return null;
  }

  get currentSubscriptionName() {
    if (this.workspace.currentSubscription && this.workspace.currentSubscription.plan) {
      return this.workspace.currentSubscription.plan.stripeProduct.name;
    } else {
      return 'No plan';
    }
  }

  created() {
    if(this.workspace.currentSubscription && this.workspace.currentSubscription.hasAccess){
      this.isActiveId = this.workspace.currentSubscription.plan.stripeProduct.id;
      this.activePlanPrice = this.workspace.currentSubscription.plan.price;
    }else{
      if(this.products.length > 0) {
        //@ts-ignore
        this.isActiveId = this.products[0].stripeProduct.id;
        this.activePlanPrice = 0;
        const topPrice = this.products.reduce((a: any, b: any) => a.Price > b.Price ? a : b, {});
        //@ts-ignore
        this.selectedPlanId = topPrice.stripeProduct.id;
        //@ts-ignore
        this.selectedPlanPrice = topPrice.price
     
      }

    }
  }

  handleUpgradeClick(product: any) {
    this.loadingPlanId = product.stripeProduct.id;
    
    this.selectedPlanId = product.stripeProduct.id;
    this.selectedPlanPrice = product.price;
    this.selectedPlanName = product.stripeProduct.name;
    const billingPeriod = this.yearlyPicked ? 'yearly' : 'monthly';
   
    this.submitFeedback(product.stripeProduct.name);
    
    //@ts-ignore
    this.proceedToCheckout(this.selectedPlanId, billingPeriod);

  }

  handleBackClick() {
    this.$router.push({ name: 'dashboard-layout' })
  }

  // setSelectedPlan(product: any) {
  //   this.selectedPlanId = product.stripeProduct.id;
  //   this.selectedPlanPrice = product.price;
  //   this.selectedPlanName = product.stripeProduct.name;
  //   //@ts-ignore
  //   this.proceedToCheckout(this.selectedPlanId);
  // }

  // handelStoreCommit() {
  //   if(this.selectedPlanName === "Pro Plan") {
  //     this.$store.commit('set_coupon_code', 'EARLYBIRD20');
  //   } else if(this.selectedPlanName === "Standard Plan") {
  //     this.$store.commit('set_coupon_code', 'EARLYBIRD40');
  //   }
  // }

  proceedToCheckout(productId: string, billingPeriod: string) {
    this.submitted = true;
    if(this.$store.state.me.isFake) {
      this.openVerifyUser();
    }
    if(this.selectedPlanPrice == 0) {
      this.submitted = false;
      this.$buefy.modal.open({
        parent: this,
        component: DowngradeWarningModal,
        events: {
          //@ts-ignore ToDo: fix type issue
          'confirmed': value => {
            if (value === true) {
              this.cancelSubscription()
            }
          }
        },
        width: '500px',
      });
  
    }else{
      
      setTimeout(() => this.upgrade(productId, billingPeriod), 1500);
    }
  }

  cancelSubscription() {
    this.$apollo.mutate({
      mutation: CancelSubscription,
      variables: {
        subscription_id: this.workspace.currentSubscription.subscription_id,
        workspace_id: this.workspace.id
      },
      refetchQueries:[{ query: WorkspaceQuery, variables: { pid: this.$store.state.active_workspace_pid } }]
    }).then((_result: any) => {
      this.$buefy.toast.open({
        message: 'The subscription will be downgraded to Free plan at the end of billing period.',
        position: 'is-bottom-right',
        type: 'is-black',
      });
      this.$router.push({name: 'workspace-layout'});
    })
  }

  upgrade(productId: string, billingPeriod: string) {
    this.$router.push({name: 'workspace-checkout', params: {productId: productId, billingPeriod: billingPeriod}});
    this.submitted = false;
    this.loadingPlanId = null;
  }

  planDetails(productId: string, interval: string) {
    return this.$store.state.plans.filter((plan: any) => {
      return plan.interval == interval && plan.stripeProduct.id == productId;
    })[0]
  }

  downgradeToFreePlan() {
    this.openDowngradeModal()
    // this.$router.push({name: 'workspace-layout'});
  }

  submitFeedback(plan: string) {
    this.$gtag.event('pmb_user_feedback_submitted');
    this.$apollo
      .mutate({
        mutation: CreateFeedbackMutation,
        variables: {
          creator_id: this.meId,
          workspace_id: this.workspaceId,
          feedback_type_id: this.feedbackTypeId,
          title: "User picked plan: " + plan,
          description: "User: " + this.me.name + "  Email: " + this.me.email + " Total activites: " + this.activitesCreated + " Account created: " + this.me.created_at
        },
      })
  }

  openVerifyUser() {
    this.$buefy.modal.open({
      component: VerifyUser,
      props: {
        clickedClaim: true,
        claimWs: true,
        fromPlanSelector: true,
      },
      events: {
        
        'account-claimed': (value: boolean) => { 
          if (value === true) {
          
            
            this.openDowngradeModal();
            
            
          }
        }
      },
      width: '500px',
      parent: this,
      canCancel: false,
    });
  }

  openDowngradeModal() {
   
    this.$buefy.modal.open({
      component: Downgrade,
      // @ts-ignore
      width: '830px',
      parent: this,
    });
    
  }

  handleOptOut() {
    if(this.onGuestAccount) {
      this.openVerifyUser();
    }
    else {
      this.openDowngradeModal()
    }
  }

  mounted() {
    this.$store.commit('set_checkout_step', 1);
  }

}
