





































import {Component, Vue} from 'vue-property-decorator';

@Component({
  components: {},
})
export default class DowngradeWarningModal extends Vue {
  confirmDeleteLoading: boolean = false;


  confirmDelete() {
    this.$emit('confirmed', true);
    this.confirmDeleteLoading = false;
    //@ts-ignore
    this.$parent.close();
  }

}
