
















































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { feedback_types } from "@/graphql/feedback";
import { CreateFeedbackMutation } from '@/graphql/feedback';
import { UpdateUserMutation, MeQuery } from "@/graphql/auth";

@Component({
  apollo: {
    feedback_types: {
      query: feedback_types,
      result(_result) {
        this.feedbackTypeId = _result.data.feedback_types[0].id;
      }
    },
  },
  components: {},
})
export default class Downgrade extends Vue {
  loading: boolean = false;
  downgradeLoading: boolean = false;
  canDowngrade: boolean = false;
  customInputActive: boolean = false;

  // Initialize feedbackTitle without using `this.me` to avoid undefined during initialization
  feedbackTitle: string = "User opted out from downgrade.";
  feedbackDescription: string | null = null;
  feedbackTypeId: string = ""

  feedbacks: string = "";
  onStep: number = 1;

  PresetOptOuts: Array<string> = [
    "Haven't had enough time to test it",
    "I rather use a simpler, but free version",
    "The pricing is a bit high for me",
    "Not sure yet",
  ];

  @Prop({required: false, default: false})
  secondOffer!: boolean;

  @Prop({required: false, default: false})
  fromSidebar!: boolean;

  get workspace() {
    return this.$store.state.workspace;
  }

  get activitesCreated() {
    return this.workspace.totalObjectivesCount;
  }

  get workspaceId() {
    return this.$store.state.workspace.id;
  }

  get meId() {
    return this.$store.state.me.id;
  }

  get meScore() {
    return this.$store.state.me.engagement_score;
  }

  get me() {
    return this.$store.state.me;
  }

  getLetter(index: number): string {
    // Get the letter corresponding to the index (A, B, C, etc.)
    return String.fromCharCode(65 + index); // 65 is the char code for 'A'
  }

  nextStep() {
    if (this.onStep === 2) {
      this.closeModal();
      this.$router.push({ name: 'dashboard-layout' });
    }
    this.onStep++;
  }

  handleClose() {
    this.$emit('close');
  }

  /**
   * Handles feedback submission from preset choices.
   * @param choice The selected feedback choice.
   */
  submitFeedbackChoice(choice: string) {
    this.feedbackDescription = choice;
    this.submitFeedback();
  }

  handleBack() {
    this.$emit('back')
  }

  submitFeedbackForm() {
    if (!this.feedbackDescription || this.feedbackDescription.trim() === "") {
      this.$buefy.toast.open({
        message: 'Please enter a description.',
        type: 'is-danger',
      });
      return;
    }
    this.submitFeedback();
  }

  submitFeedback() {
    this.feedbackTitle = `User denied first offer. User: ${this.me.name}, Email: ${this.me.email}, Total activities: ${this.activitesCreated}, Account created: ${this.me.created_at}`;
    
    this.$gtag.event('pmb_user_feedback_submitted');
    this.$apollo
      .mutate({
        mutation: CreateFeedbackMutation,
        variables: {
          creator_id: this.meId,
          workspace_id: this.workspaceId,
          feedback_type_id: this.feedbackTypeId,
          title: this.feedbackTitle,
          description: this.feedbackDescription
        },
      })
      .then(() => {
        // If user denies second offer, downgrade to freemium 
        // if(this.secondOffer && !this.fromSidebar) {
        //   this.setUserAccess();
        // }
        this.$gtag.event('pmb_user_plan_opt_out');
        this.feedbackTitle = "";
        this.feedbackDescription = "";
        this.nextStep();
      })
      .catch(error => {
        console.error('Feedback submission failed:', error);
        this.$buefy.toast.open({
          message: 'Failed to submit feedback. Please try again.',
          type: 'is-danger',
        });
      });
  }

  setUserAccess() {
    this.$apollo
      .mutate({
        mutation: UpdateUserMutation,
        variables: {
          id: this.meId,
          has_freemium_access: true,
        },
        awaitRefetchQueries: true,
        refetchQueries: [{ query: MeQuery }],
      })
      .then(() => {
        this.$buefy.toast.open({
          message: 'Account downgraded',
          position: 'is-bottom-right',
          type: 'is-black',
        });
      })
      .catch(error => {
        console.error('Failed to update user access:', error);
        this.$buefy.toast.open({
          message: 'Failed to update access. Please contact support.',
          type: 'is-danger',
        });
      });
  }

  routeToCheckout() {
    this.$router.push({ name: 'checkout-flow' });
    //@ts-ignore
    this.$parent.close();
  }

  downgradeAccount() {
    this.downgradeLoading = true;
    this.$store.commit('set_trial_ended', true); 
    setTimeout(() => {
      this.$router.push({ name: 'workspace-layout' });
      //@ts-ignore
      this.$parent.close();
    }, 1000);
  }
  
  toggleCustomInput() {
    this.customInputActive = !this.customInputActive;
  }

  mounted() {
    this.$gtag.event('pmb_app_downgrade_modal_mounted');
  }
  
  closeModal() {
    // @ts-ignore
    this.$parent.close(); 
  }
}
