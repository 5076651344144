<template>
  <section class="section has-margin-top-4 has-margin-bottom-4 has-padding-y-5" style="background: #f5f4f0; border-radius: 20px;">
    <div class="container">
      <div class="columns is-centered">
        <div class="column is-10">
          <h2 class="subtitle has-text-weight-bold has-text-black has-font-gt is-2">
            FAQ
          </h2>
          <div class="content size-18px has-text-black hover-cursor"
               v-for="(item, i) in faq.slice(0, maxListed)"
               :key="item.question"
               @click="currentIndex = i"
          >
            <hr>
            <h3 class="subtitle is-5 has-text-black has-margin-bottom-1 has-text-weight-bold"
            >
              {{ item.question }}
            </h3>
            <p v-show="currentIndex === i">
              {{ item.answer }}
            </p>
          </div>
          <template v-if="faq.length > maxListed">
            <p class="has-opacity-07 has-text-weight-bold hover-opacity-05 has-text-black hover-cursor"
               @click="maxListed = 30">
              Show more
            </p>
          </template>
        </div>
      </div>
    </div>
  </section>

</template>

<script>
export default {
  name: "GeneralFAQ",
  data() {
    return {
      currentIndex: 999,
      maxListed: 7,
      faq: [
        { question: 'Can I use Pembio for free?',
          answer: 'There will always be a free version of Pembio, which is suitable for users with basic requirements on goal and work planning. However, the app is much more powerful and feature-rich on our paid plans.' 
        },
        { question: 'What is a Workspace?',
          answer: 'A workspace is the contained space of all the work you, your team and other collaborators are doing in Pembio. Each workspace contains one or more rooms, and each room is comprised of one or more people working on one or more goals.' 
        },
        { question: 'How many people are included in a Pro account?',
          answer: 'You can add as many people as you want on a Pro account.'
        },
        { question: 'Why is each Workspace priced individually?',
          answer: 'We believe that you should only pay for what you need. So while some paid-for features are necessary to get your most important work done in one particular workspace, it might suffice for you to have access to a more limited - and free - feature set in another workspace.' 
        },
        { question: 'How do I cancel a plan?',
          answer: 'You can cancel your subscription at any time, and the changes will be reflected at the next billing cycle'
        },
        { question: 'What happens if I have a Pro account and then upgrade to Pro Plus?',
          answer: 'Your account will be updated accordingly by the next billing cycle. '
        },
        { question: 'How are you handling my data?',
          answer: 'The data security of our users is our top priority. We have therefore built Pembio in accordance with the strictest data security regulations, including the GDPR framework in Europe.'
        }
      ],
    }
  },
}
</script>

<style scoped>

</style>